import React, { Component } from 'react';
import {ReactFlvPlayer} from 'react-flv-player'

class LivestreamVideo extends Component {

  render() {
    return (
      <div>
        <ReactFlvPlayer
        
          url = "http://localhost:8000/live/icon.flv"
          heigh = "100%"
          width = "100%"
          isMuted={true}
          handleError={(err) => {
            window.location.reload(true);
            switch (err) {
              case 'NetworkError':
                // todo
                console.log('network error');
              break;
              case 'MediaError':
                console.log('network error');
              break;
              default:
                console.log('other error');
            }
          }}
        />
      </div>
    );
  }
}

export default LivestreamVideo;
