import React from "react";
import PropTypes from "prop-types";

const ListItem = ({hero, index, text}) => {
  return (
    <div className={`list-item${(hero ? ' hero' : '')}`}>
      <span>{(index > 0 ? index : '')}</span>
      <span>{text}</span>
    </div>
  )
}

ListItem.propTypes = {
  hero: PropTypes.bool,
  index: PropTypes.number,
  text: PropTypes.string,
};
ListItem.defaultProps = {
  hero: false,
  index: 0,
  text: ""
}

export default ListItem;
