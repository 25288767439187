import React, {useState, useEffect} from "react";
import {List} from "react-movable";
import {useSelector, useDispatch} from "react-redux";
import {getPlayers, getPlayersInLine, reorderPlayers} from "../../core/actions/playerActions";
import ActivatePlayerModal from "./activate-player-modal";
import DeactivatePlayerModal from "./deactivate-player-modal";
import DeletePlayerModal from "./delete-player-modal";
import RemovePlayerFromLineModal from "./remove-from-line-modal";
import OnDeckPlayerModal from "./on-deck-player-modal";
import RecordDistanceModal from "./record-distance-modal";
import DraggableTarget from "../ui-elements/draggable-target";
import FlagButton from "../ui-elements/flag-button";
import TrashButton from "../ui-elements/trash-button";
import StarButton from "../ui-elements/star-button";
import UpArrow from "../ui-elements/up-arrow-button";
import InlinePlayerSearch from "./player-search";

import NavbarReturn from "../navigation/navbar-return";

import PageReload from "./page-reload";
import RemoveFromLineButton from "../ui-elements/remove-from-line-button";

const ManagePlayerQueue = () => {

  const dispatch = useDispatch();

  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [playerToActivate, setPlayerToActivate] = useState({});

  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false);
  const [playerToDeactivate, setPlayerToDeactivate] = useState({});

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState({});

  const [removeInlinePlayerModalVisible, setRemoveInlinePlayerModalVisible] = useState(false);
  const [playerToRemoveFromLine, setPlayerToRemoveFromLine] = useState({});

  const [onDeckModalVisible, setOnDeckModalVisible] = useState(false);
  const [playerOnDeck, setPlayerOnDeck] = useState({});

  const [recordDistanceModalVisible, setRecordDistanceModalVisible] = useState(false);
  const [playerToSave, setPlayerToSave] = useState({});
  const [showAddToLineModal, toggleShowAddToLineModal] = useState(false);


  const currentPlayers = useSelector(state => state.players.inlinePlayers)
    .sort((a, b) => (Number(a.order) - Number(b.order))) || [];

  const registeredPlayers = useSelector(state => state.players.registeredPlayers)
    .sort((a, b) => (Number(a.order) - Number(b.order))) || [];

  
  const activePlayer = currentPlayers.filter(player => player.active)[0] || {};

  const nonActivePlayers = currentPlayers.filter(player => (!player.active && !player.on_deck)) || [];

  const nextPlayer = currentPlayers.filter(player => player.on_deck)[0] || {};

  const manageablePlayers = nonActivePlayers || [];

  const onRecordDistance = (player) => {
    setRecordDistanceModalVisible(true);
    setPlayerToSave(player);
  };
  const onRecordDistanceClose = (player) => {
    setRecordDistanceModalVisible(false);
    setPlayerToSave({});
  };

  const onActivate = (player) => {
    if (Object.keys(activePlayer).length === 0) {
      setActivateModalVisible(true);
      setPlayerToActivate(player);
    }
  };
  const onActivatePlayerClose = () => {
    setActivateModalVisible(false);
    setPlayerToActivate({});
  };

  const onDeactivate = (player) => {
    setDeactivateModalVisible(true);
    setPlayerToDeactivate(player);
  };
  const onDeactivatePlayerClose = () => {
    setDeactivateModalVisible(false);
    setPlayerToDeactivate({});
  };

  const onDelete = (player) => {
    setDeleteModalVisible(true);
    setPlayerToDelete(player);
  };
  const onDeletePlayerClose = () => {
    setDeleteModalVisible(false);
    setPlayerToDelete({});
  };

  const onRemoveFromLine = (player) => {
    console.log('hit')
    setRemoveInlinePlayerModalVisible(true);
    setPlayerToRemoveFromLine(player);
  };
  const onRemoveFromLineClose = () => {
    setRemoveInlinePlayerModalVisible(false);
    setPlayerToRemoveFromLine({});
  };

  const onMoveToOnDeck = (player) => {
    if (Object.keys(nextPlayer).length === 0) {
      setOnDeckModalVisible(true);
      setPlayerOnDeck(player);
    }
  };
  const onOnDeckModalClose = () => {
    setOnDeckModalVisible(false);
    setPlayerOnDeck({});
  };

  const openAddToLine = () => {
    // navigate('/inline-player-search');
  }

  const onPlayerMoved = (oldIndex, newIndex) => {

    const numPlayers = manageablePlayers.length;

    let middleOfDiff = 0;
    let newOrder = 0;
    let direction = "";

    if (newIndex < oldIndex) {
      direction = "up";
    }
    if (newIndex > oldIndex) {
      direction = "down";
    }
    if (direction === "down") {
      if (newIndex < numPlayers - 1) {
        middleOfDiff = Math.round((Number(manageablePlayers[newIndex + 1].order) - Number(manageablePlayers[newIndex].order)) / 2);
        newOrder = Number(manageablePlayers[newIndex + 1].order) - Number(middleOfDiff);
      } else {
        middleOfDiff = Math.round((Number(manageablePlayers[numPlayers - 1].order) - Number(manageablePlayers[numPlayers - 2].order)) / 2);
        newOrder = Number(manageablePlayers[numPlayers - 1].order) + Number(middleOfDiff);
      }
    }

    if (direction === "up") {
      if (newIndex === 0) {
        middleOfDiff = Math.round((Number(manageablePlayers[1].order) - Number(manageablePlayers[0].order)) / 2);
        newOrder = Number(manageablePlayers[0].order) - Number(middleOfDiff);
      } else {
        middleOfDiff = Math.round((Number(manageablePlayers[newIndex + 1].order) - Number(manageablePlayers[newIndex].order)) / 2);
        newOrder = Number(manageablePlayers[newIndex - 1].order) + Number(middleOfDiff);
      }
    }

    const reorderObj = {
      id: manageablePlayers[oldIndex]._id,
      new_order: newOrder.toString()
    }

    dispatch(reorderPlayers(reorderObj));

  }

  const Player = ({props, player, status = 'in-queue', isDragged}) => {

    const firstName = player.first_name;
    const lastName = player.last_name;
    const distanceFeet = player.trackman_json_data?.distanceFeet || "";
    const distanceInches = player.trackman_json_data?.distanceInches || "";
    return (
      <div className="player">
        {props &&
        <span>
          <span className="player-indexed">
            <span className="player-number">{`${props.key + 1 || ""}`}</span>
            <span className="player-name">{`${firstName} ${lastName}`}</span>
          </span>
          {props.key === 0 &&
            <span className="move-to-on-deck">
              <UpArrow clickFn={() => {onMoveToOnDeck(player)}} />
            </span>
          }
          <span className="move-from-line">
            {!isDragged && <RemoveFromLineButton clickFn={() => {onRemoveFromLine(player)}} />}
          </span>
          <span className="move-to-trash">
            {!isDragged && <TrashButton clickFn={() => {onDelete(player)}} />}
          </span>
          {!isDragged && <DraggableTarget />}
        </span>
        }
        {!props && status === 'active' &&
          <span className="player-non-indexed">
            {player.active &&
              <>
                <StarButton clickFn={() => onDeactivate(player)} isActive={true}/>
                <span className="player-name">{`${firstName} ${lastName}`}</span>
                {(distanceFeet && distanceInches) && (<span className="player-distance">{`${distanceFeet}' ${distanceInches}"`}</span>)}
                <FlagButton clickFn={() => onRecordDistance(player)} />
              </>
            }
          </span>
        }
        {!props && status === 'on-deck' &&
          <span className="player-non-indexed">
            {player.on_deck &&
              <>
                <StarButton clickFn={() => onActivate(player)}/>
                <span className="player-name">{`${firstName} ${lastName}`}</span>
                <TrashButton clickFn={() => {onDelete(player)}}/>
              </>
            }
          </span>
        }
      </div>
    );
  }

  useEffect(() => {
    dispatch(getPlayersInLine());
  }, []);


  return (
    <>
    {showAddToLineModal ? (
<div style={{positon: 'fixed', left: 0, top: 0, width: '100vw', background: 'white'}}>

  
<InlinePlayerSearch data={registeredPlayers} toggleShowAddToLineModal={toggleShowAddToLineModal} showAddToLineModal={showAddToLineModal} />

</div>
    ): ''}
    
    <div className="manage-player-queue">
      <NavbarReturn returnText={"CLOSE"} to="/dashboard"/>
      <div className="player-list">
        <PageReload />
        {removeInlinePlayerModalVisible &&
          <RemovePlayerFromLineModal closeFn={onRemoveFromLineClose} player={playerToRemoveFromLine} />}
                  {deleteModalVisible &&
          <DeletePlayerModal closeFn={onDeletePlayerClose} player={playerToDelete} />}
        {deactivateModalVisible &&
          <DeactivatePlayerModal closeFn={onDeactivatePlayerClose} player={playerToDeactivate} />}
        {activateModalVisible &&
          <ActivatePlayerModal closeFn={onActivatePlayerClose} player={playerToActivate} />}
        {recordDistanceModalVisible &&
          <RecordDistanceModal closeFn={onRecordDistanceClose} player={playerToSave} />}
        {onDeckModalVisible &&
          <OnDeckPlayerModal closeFn={onOnDeckModalClose} player={playerOnDeck} />}
        <div className="title">PLAYER QUEUE</div>
        <div className="current-player">CURRENT PLAYER</div>
        <div className="static"><Player player={activePlayer} props={null} status='active'/></div>
        <div className="next-player">ON DECK</div>
        <div className="static"><Player player={nextPlayer} props={null} status='on-deck'/></div>
        <div className="upcoming-players">UPCOMING</div>
        <span onClick={() => toggleShowAddToLineModal(!showAddToLineModal)}> ADD PLAYER TO LINE</span>
        <div>
          <List
            lockVertically
            values={nonActivePlayers}
            onChange={
              ({oldIndex, newIndex}) => {
                onPlayerMoved(oldIndex, newIndex);
              }
            }
            renderList={({children, props}) => <ul{...props}>{children}</ul>}
            renderItem={({value, props, isDragged, isSelected}) =>
              <li className="draggable" {...props}
                  style={{
                    ...props.style,
                    listStyleType: 'none',
                    height: '80px',
                    backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF',
                    fontFamily: isDragged && 'GenesisSansTextMedium',
                    fontSize: isDragged && '22px',
                    textTransform: 'uppercase',
                    paddingTop: isDragged && '28px',
                    paddingLeft: isDragged && '30px'
                  }}
              >
                <Player props={props} player={value} isDragged={isDragged}/>
              </li>
            }
          />
        </div>
      </div>
    </div>
    </>
  );
}

export default ManagePlayerQueue;
