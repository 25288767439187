import React from "react";
import PropTypes from "prop-types";

const ListHeader = ({style, text}) => {
  return (
    <div className="list-header" style={style}>
      <span>{text}</span>
    </div>
  );
}

ListHeader.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string
};
ListHeader.defaultProps = {
  style: {},
  text: ""
}

export default ListHeader;
