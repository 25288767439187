import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {LinkButton, FrameButton} from "../ui-elements";
import {removeInlinePlayer} from "../../core/actions/playerActions";

const RemoveFromLineModal = ({closeFn, player}) => {

  const dispatch = useDispatch();

  const onCancel = () => {
    closeFn();
  };

  const onConfirm = () => {
    dispatch(removeInlinePlayer(player));
    closeFn();
  };

  return (
    <div className="delete-player-modal" style={{textAlign: 'center'}}>
      <div className="modal-frame">
        <h1>Are you sure you want to remove {player.first_name} from the line?</h1>
        <div className="control-buttons">
          <div>
            <FrameButton clickFn={onConfirm} text="YES"/>
          </div>
          <div>
            <LinkButton clickFn={onCancel} text="NO"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveFromLineModal;

RemoveFromLineModal.propTypes = {
  closeFn: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired
};