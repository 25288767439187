import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getPlayers,getPlayersInLine} from "../../core/actions/playerActions";

import {FrameButton} from "../ui-elements";
import StarButton from "../ui-elements/star-button";
import PageReload from "../player-management/page-reload";

const Dashboard = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentPlayers = useSelector(state => state.players.inlinePlayers)
  .sort((a, b) => (Number(a.order) - Number(b.order))) || [];
  const activePlayer = currentPlayers.filter(player => player.active)[0] || {};
  const nextPlayer = currentPlayers.filter(player => player.on_deck)[0] || {};
  const nonActivePlayers = currentPlayers.filter(player => (!player.active && !player.on_deck)) || [];

  const onManagePlayers = () => {
    navigate('/player-management-queue');
  }

  const onRegisterPlayers = () => {
    navigate('/register-players');
  }

  const Player = ({index, player}) => {
    console.log(player);
    console.log(index);

    const isWaiting = (!player.on_deck || !player.active)

    return (
      <div className="player">
        {(player.on_deck || player.active) && <StarButton isActive={player.active}/>}
        <span className="is-waiting index">{index && index}</span>
        <span className={isWaiting ? 'is-waiting' : 'not-waiting'}>{player.first_name}</span>
        <span className={isWaiting ? 'is-waiting' : 'not-waiting'}>{player.last_name}</span>
      </div>
    );
  }

  useEffect(() => {
    dispatch(getPlayersInLine());
  }, []);

  return (
    <div className="dashboard">
      <PageReload />
      <h1>Admin Dashboard</h1>
      <div className="controls">
        <FrameButton clickFn={onRegisterPlayers} text={'Register Players'}/>
        <FrameButton clickFn={onManagePlayers} text={'Manage Players'}/>

      </div>
      <div className="players">
        <h2>Player Queue</h2>
        <div className="active-player">
          <h3>CURRENT PLAYER</h3>
          <Player player={activePlayer}/>
        </div>

        <div className="next-player">
          <h3>ON DECK</h3>
          <Player player={nextPlayer}/>
        </div>

        <div className="waiting-players">
          <h3>UPCOMING</h3>
          <ul>
            {nonActivePlayers.map((player, index) => {
              return (
                <li key={index}>
                  <Player index={index + 1} player={player}/>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
