import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getPlayers} from "../../core/actions/playerActions"

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import BrandLogo from "../../images/brand-logo.svg"
import {ListHeader, ListItem} from "../ui-elements";

const PlayerQueue = ({loop}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentPlayers = useSelector(state => state.players.currentPlayers)
    .sort((a, b) => (Number(a.order) - Number(b.order))) || [];
  const activePlayer = currentPlayers.filter(player => player.active)[0] || {};
  const nextPlayer = currentPlayers.filter(player => player.on_deck)[0] || {};
  const nonActivePlayers = currentPlayers.filter(player => (!player.active && !player.on_deck)) || [];
  const firstTwelvePlayers = nonActivePlayers.slice(0, 12);

  let sets = [];
  let tempSet = [];
  let tick = 0;
  for (let i = 12; i < nonActivePlayers.length; i++) {
    nonActivePlayers[i].placeNumber = i + 1;
    tempSet.push(nonActivePlayers[i]);
    tick++;
    if (tick === 20) {
      tick = 0;
      sets.push([...tempSet]);
      tempSet = [];
    }
  }
  if (tempSet.length > 0) {
    sets.push([...tempSet]);
  }

  useEffect(() => {
    dispatch(getPlayers());
  }, []);

  const getSections = () => {

    const InitialSection = () => {
      return (
        <div className="screen players-list-init">
          <div className="top-players">
            {activePlayer &&
            <div className="column">
              <ListHeader text="CURRENT PLAYER"/>
              <div className={"list-item hero"}>
                <div>{activePlayer.first_name || ''}</div>
                <div>{activePlayer.last_name || ''}</div>
              </div>
            </div>
            }
            {nextPlayer &&
            <div className="column">
              <ListHeader text="ON DECK"/>
              <div className={"list-item hero"}>
                <div>{nextPlayer.first_name || ''}</div>
                <div>{nextPlayer.last_name || ''}</div>
              </div>
            </div>

            }
          </div>
          <ListHeader text="UPCOMING"/>
          <div className="current-players">
            <ul>
              {firstTwelvePlayers.map((player, index) => {
                return (
                  <li key={index}>
                    <ListItem hero={false} index={index + 1} text={`${player.first_name} ${player.last_name}`}/>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      );
    }

    const MiddleSection = ({players}) => {
      return (
        <div className="screen players-list-full">
          <ListHeader text="UPCOMING"/>
          <div className="current-players">
            <ul>
              {players.map((player, index) => {
                return (
                  <li key={index}>
                    <ListItem hero={false} index={player.placeNumber}
                              text={`${player.first_name} ${player.last_name}`}/>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      );
    }

    const FinalSection = () => {
      return (
        <div className="screen players-list-trail">
          <ListHeader text="UPCOMING"/>
          <div className="current-players">
            <ul>
              {sets[sets.length - 1].map((player, index) => {
                return (
                  <li key={index}>
                    <ListItem hero={false} index={player.placeNumber}
                              text={`${player.first_name} ${player.last_name}`}/>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      );
    }

    let items = [];

    items.push(<InitialSection/>);

    if (sets.length > 1) {
      sets.forEach((set, index) => {
        if (set.length === 20) {
          items.push(<MiddleSection players={set}/>);
        }
      });
    }

    if (sets.length > 0 && sets[sets.length - 1].length <= 20) {
      items.push(<FinalSection/>);
    }

    return items;

  }

  console.log(loop);

  return (
    <div className="player-queue">
      {/*<div className="dev-frame">&nbsp;</div>*/}
      <div className="header">
        <div className="brand">
          <img src={BrandLogo}/>
        </div>
        <div className="title-1">CLOSEST TO THE PIN CHALLENGE</div>
        <div className="title-2">LINE QUEUE</div>
      </div>
      <div className="queue">
        <AliceCarousel
          infinite={true}
          disableButtonsControls={true}
          disableDotsControls={true}
          autoPlay={true}
          autoPlayInterval={7000}
          autoHeight={true}
          animationDuration={1000}
          items={getSections()}
          onSlideChanged={(e) => {
            if (e.item === 0) {
              if (loop) {
                dispatch(getPlayers());
              }
            }
            if (e.item === sets.length) {
              if (!loop) {
                setTimeout(() => {
                  navigate('/sizzle2');
                }, 7000);
              }
            }
          }}
        />
      </div>
    </div>
  );
}

export default PlayerQueue;

PlayerQueue.propTypes = {
  loop: PropTypes.bool
}

PlayerQueue.defaultProps = {
  loop: true
}
