import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import BrandLogo from "../../images/brand-logo.svg";
import {FrameButton} from "../ui-elements";
import {getPlayers} from "../../core/actions/playerActions";

const PlayerRegistrationComplete = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const currentPlayers = useSelector(state => state.players.currentPlayers);

  const onNewSignUp = () => {
    navigate("/player-registration-form");
  }

  useEffect(() => {
    dispatch(getPlayers());
  }, []);

  return (
    <div className="player-registration-complete">
      <div className="header">
        <div className="brand">
          <img src={BrandLogo}/>
        </div>
        <div className="title">CLOSEST TO THE PIN CHALLENGE</div>
      </div>
      <div className="success">SUCCESS!</div>
      {/* <div className="line-place">YOUR CURRENT PLACE IN LINE IS</div>
      <div className="count">{currentPlayers.length}</div> */}
      <div className="new-signup">
        <FrameButton text="NEW REGISTRATION" clickFn={onNewSignUp}/>
      </div>
      <div className="close">
        <Link to="/register-players">CLOSE</Link>
      </div>
    </div>
  );
}

export default PlayerRegistrationComplete;
