import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import {inlinePlayer,getPlayers, deletePlayer} from "../../core/actions/playerActions"
import {useDispatch, useSelector} from "react-redux";
import dataService from "../../core/services/data.service";
import TrashButton from "../ui-elements/trash-button";
import DeletePlayerModal from "./delete-player-modal";

import PlusSign from '../../images/plus.png'
const InlinePlayerSearch = (props) => {
  const [searchData, setSearchData] = useState([]);
  const [showRefresh, setShowRefresh] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState({});

  const dispatch = useDispatch();

  const onDelete = (player) => {
    setDeleteModalVisible(true);
    setPlayerToDelete(player);
  };
  const onDeletePlayerClose = () => {
    setDeleteModalVisible(false);
    setPlayerToDelete({});
  };

  const searchItem = (query) => {
    const initalData = searchData;
    if (!query) {
      getData();
      return;
    }
    const fuse = new Fuse(searchData, {
      keys: ["first_name", "last_name", "email"]
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      setSearchData(finalResult);
    } else {
      setSearchData([]);
    }
  };

  const getData = () => {
    dataService('/players/get-registered-players', 'get')
    .then((response) => {
      console.log(response.data.filter(res => res.in_line === false))
      let filteredData = response.data.filter(res => res.in_line === false);
      setSearchData(filteredData)
    })
    .catch(err => console.log(err)
    );
  }


  useEffect(() => {
    getData()
   
  }, []);
  return (
    <>
                  {deleteModalVisible &&
          <DeletePlayerModal closeFn={onDeletePlayerClose} player={playerToDelete} />}
    <div>

      <span onClick={() => props.toggleShowAddToLineModal(!props.showAddToLineModal)} style={{fontSize: '20px', marginLeft: '5%', fontFamily:  "GenesisSansHead", letterSpacing: '1.5px'}}><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAYCAIAAAD78wNdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LmVkYTJiM2ZhYywgMjAyMS8xMS8xNy0xNzoyMzoxOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo2ODUyMjVlZS0yYmVjLTRjZmQtODRiYi05MjAzYjk1MWFiZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjdEMTk4N0E1RjdBMTFFQ0IxMzdFMEUzMkNCNDBCRkMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjdEMTk4Nzk1RjdBMTFFQ0IxMzdFMEUzMkNCNDBCRkMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjEgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2ODUyMjVlZS0yYmVjLTRjZmQtODRiYi05MjAzYjk1MWFiZTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Njg1MjI1ZWUtMmJlYy00Y2ZkLTg0YmItOTIwM2I5NTFhYmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+TpFobgAAAMJJREFUeNq01r0NhSAUBeBLsLKBhj1scRlGoKNlBiZgBEeRTXADn8aXGA1Cwb2npfgSfs4F9la89znnvSNQXzbGAMCyLFTGBcQY974ANfBpIAJlAxcoGOjA26AAHgYRcBt0wN8gBY4wa20IYRgGzjkQJKXE1nWd51kp5ZwbxxHd0Fqfe3UwQohpmjq7r3HmpMx9d+mYxxskYt5dQsEUOhGdKXc7LvM5oxCZ2qzFYhp/hovp/DOw06lm2zYpZU+d/AQYAIszsL5B32FsAAAAAElFTkSuQmCC" width="19" height="auto"/> CLOSE</span>

    <p className="title" style={{marginLeft: '5%'}}>Add Players to Line</p>
      <div className="search-container">
        <input
        style={{width: '90%',marginLeft: '5%', backgroundColor: '#eff0f0', padding: '15px', border: 'none'}}
          type="search"
          onChange={(e) => searchItem(e.target.value)}
          placeholder="Search"
        />
      </div>

    <div className="item-container"         style={{width: '90%',marginLeft: '5%', padding: '5px'}}>
        {searchData.map((item) => (
          <div key={item._id } style={{display: 'flex', justifyContent: 'space-between'}}>
            <span>
            {item.first_name}  {item.last_name}, <i>{item.email}</i> </span>
            <div style={{display: 'flex'}}>

            <div onClick={() => dispatch(inlinePlayer(item._id))} > <img src={PlusSign} style={{height: '36px', marginRight: '20px'}}/></div>
            <TrashButton clickFn={() => {onDelete(item)}} style={{border: 'none'}}/>
            </div>
            </div>

        ))}
      </div>
    </div></>
  );
};

export default InlinePlayerSearch;