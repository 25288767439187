import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {getLeaderboard} from "../../core/actions/playerActions"
import PropTypes from 'prop-types'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import BrandLogo from "../../images/brand-logo.svg"

const Leaderboard = ({loop, livestream}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  function addValues(feetInInches, inches) {
    return (Number(feetInInches * 12)) + Number(inches);
  }

  const players = [...useSelector(state => state.players.leaderboard)] || [];

  players.forEach((player, index) => {
    players[index].totalInches = addValues(player.trackman_json_data.distanceFeet, player.trackman_json_data.distanceInches);
  });

  const allLeaderboardPlayers = [...players.sort((a, b) => a.totalInches - b.totalInches)];

  let leaderboardPlayers = [];

  if (allLeaderboardPlayers.length > 56) {
    leaderboardPlayers = allLeaderboardPlayers.slice(0,56);
  } else {
    leaderboardPlayers = allLeaderboardPlayers;
  }

  leaderboardPlayers.forEach((player, index) => {
    if (index > 0 && player.totalInches === leaderboardPlayers[index - 1].totalInches) {
      player.position = leaderboardPlayers[index - 1].position;
    } else {
      if (index === 0) {
        player.position = 1;
      } else {
        player.position = leaderboardPlayers[index - 1].position + 1;
      }
    }
  });

  const firstEightPlayers = leaderboardPlayers.slice(0, 8);

  let sets = [];
  let tempSet = [];
  let tick = 0;
  for (let i = 8; i < leaderboardPlayers.length; i++) {
    tempSet.push(leaderboardPlayers[i]);
    tick++;
    if (tick === 8) {
      tick = 0;
      sets.push([...tempSet]);
      tempSet = [];
    }
  }
  if (tempSet.length > 0) {
    sets.push([...tempSet]);
  }

  useEffect(() => {
    dispatch(getLeaderboard());
  }, []);

  const LeaderboardHeader = () => {
    return (
      <thead>
      <tr className="board-header">
        <th width="200">CURRENT POSITION</th>
        <th width="170">DATE</th>
        <th>NAME</th>
        <th>DISTANCE FROM CUP</th>
      </tr>
      </thead>
    )
  }

  const LeaderboardPlayer = ({data}) => {

    const isFirst = data.position === 1;

    return (
      <tr className={`player ${isFirst ? ' leader' : ' non-leader'}`} key={data.index}>
        <td>
          <span>{data.position}</span>
        </td>
        <td>{data.date.slice(4, 11)}</td>
        <td>{data.name}</td>
        <td>
          <span>{`${data.feet}' ${data.inches}"`}</span>
        </td>
      </tr>
    );
  }

  const getSections = () => {

    const InitialSection = () => {
      return (
        <div className="screen players-list-init">
          <table className="player-list">
            <LeaderboardHeader/>
            <tbody>
            {firstEightPlayers.map((player, index) => {
              const data = {
                date: new Date(player.deactivation_time * 1).toDateString(),
                position: player.position,
                name: `${player.first_name} ${player.last_name}`,
                feet: player.trackman_json_data.distanceFeet,
                inches: player.trackman_json_data.distanceInches,
                type: (index === 0 ? 'leader' : 'non-leader'),
                totalInches: player.totalInches,
                index
              };
              return (<LeaderboardPlayer key={index} data={data}/>);
            })}
            </tbody>
          </table>
        </div>
      );
    }

    const Section = ({players}) => {
      return (
        <table className="screen player-list">
          <LeaderboardHeader/>
          <tbody>
          {players.map((player, index) => {
            const data = {
              date: new Date(player.deactivation_time * 1).toDateString(),
              position: player.position,
              name: `${player.first_name} ${player.last_name}`,
              feet: player.trackman_json_data.distanceFeet,
              inches: player.trackman_json_data.distanceInches,
              type: 'non-leader',
              totalInches: player.totalInches,
              index
            };
            return (<LeaderboardPlayer key={index} data={data}/>);
          })}
          </tbody>
        </table>
      );
    }

    const FinalSection = () => {
      return (
        <div className="screen players-list-final">
          <table className="screen player-list">
            <LeaderboardHeader/>
            <tbody>
            {sets[sets.length - 1].map((player, index) => {
              const data = {
                date: new Date(player.deactivation_time * 1).toDateString(),
                position: player.position,
                name: `${player.first_name} ${player.last_name}`,
                feet: player.trackman_json_data.distanceFeet,
                inches: player.trackman_json_data.distanceInches,
                type: 'non-leader',
                totalInches: player.totalInches,
                index
              };
              return (<LeaderboardPlayer key={index} data={data}/>);
            })}
            </tbody>
          </table>
        </div>
      );
    }

    let items = [];

    items.push(<InitialSection/>);


    if (sets.length > 1) {
      sets.forEach((set) => {
        if (set.length === 8) {
          items.push(<Section players={set}/>);
        }
      });
    }

    if (sets.length > 0 && sets[sets.length - 1].length < 8) {
      items.push(<FinalSection/>);
    }

    return items;

  }

  console.log(sets);

  return (
    <div className="leaderboard">
      {/*<div className="dev-frame">&nbsp;</div>*/}
      <div className="header">
        <div className="brand">
          <img src={BrandLogo}/>
        </div>
        <div className="title-1">CLOSEST TO THE PIN CHALLENGE</div>
        <div className="title-2">Tournament Leaderboard</div>
      </div>
      <div className="content">
        <AliceCarousel
          infinite={true}
          disableButtonsControls={true}
          disableDotsControls={true}
          autoPlay={true}
          autoPlayInterval={7000}
          autoHeight={true}
          animationDuration={1000}
          items={getSections()}
          onSlideChanged={(e) => {
            if (e.item === 0) {
              if (loop) {
                dispatch(getLeaderboard());
              }
            }
            if (e.item === sets.length) {
              if (!loop) {
                setTimeout(() => {
                  navigate(livestream? `/sizzle-livestream/${id}`:'/sizzle1');
                }, 7000);
              }
            }
          }}
        />
      </div>
    </div>
  );
}

export default Leaderboard;

Leaderboard.propTypes = {
  loop: PropTypes.bool,
  livestream: PropTypes.bool
}

Leaderboard.defaultProps = {
  loop: true,
  livestream: false
}
