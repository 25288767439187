import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "../core/utils/setAuthToken";
import {Provider} from "react-redux";
import {setCurrentAdmin, logoutAdmin} from "../core/actions/authActions";
import store from "../core/stores/store";

import Navbar from "./navigation/navbar";
import {PrivateRoutes} from "./routes/private-routes";
import {PublicRoutes} from "./routes/public-routes";

import '../styles/_base.scss';

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentAdmin(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutAdmin());
    window.location.href = "./login-admin";
  }
}

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className="app">
          <Navbar/>
          <PublicRoutes/>
          <PrivateRoutes/>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
