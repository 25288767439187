import React from "react";
import PropTypes from "prop-types";

const LinkButton = ({clickFn, text}) => {
    return (
        <button onClick={clickFn} className="link-button">{text}</button>
    );
}

export default LinkButton;

LinkButton.propTypes = {
    clickFn: PropTypes.func,
    text: PropTypes.string
};

LinkButton.defaultProps = {
    clickFn: () => {},
    text: ""
}