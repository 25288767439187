import React from "react";
import PropTypes from "prop-types";
import FlagIcon from "../../images/flag-icon.png";

const FlagButton = ({clickFn}) => {
  return (
    <button onClick={clickFn} className="flag-button">
      <img src={FlagIcon} />
    </button>
  );
}

export default FlagButton;

FlagButton.propTypes = {
  clickFn: PropTypes.func
};

FlagButton.defaultProps = {
  clickFn: () => {}
}