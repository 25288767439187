import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import LivestreamVideo from "./livestream-video";

const Livestream = () => {
  
  const navigate = useNavigate();
  let { id } = useParams();

  setTimeout(() => {
    navigate(`/leaderboard-livestream/${id}`);
  }, 14000);


  return (
    <div class="video-container">
      {id == 'local' ?  <LivestreamVideo /> : <iframe src={`https://www.youtube.com/embed/${id}?&autoplay=1&mute=1&controls=0&modestbranding&rel=0`}></iframe>
}
     
      <div className="border"></div>

    </div>
  );
}

export default Livestream;
