import React from "react";
import {FrameButton, LinkButton} from "../ui-elements";
import { useIdleTimer } from 'react-idle-timer'
import { useEffect,useState } from "react";
import {Navigate, useNavigate } from "react-router-dom";

const IdleTimeoutModal = (props) => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(0);

  const onClose = () => {
    window.location.reload(true);
  };

  const onConfirm = () => {
    props.setIdleTimeout(false)

  }

  const onIdle = () => {
    navigate('/register-players-kiosk');
  }

  const onActive = (event) => {


  }
  useEffect(() => {
    if (timer < 30) {
      setTimeout(() => {
        setTimer(timer + 1);
      }, 1000);
    } else {
      setTimer(0);
      return () => {
        clearTimeout();
      };
    }
  }, [timer])
  
  const idleTimer = useIdleTimer({ onIdle, onActive, timeout: props.timeoutMili })

  return (
    <>
      <div className="player-exists-modal">
        <div className="modal-frame">
          <h1>Would You Like to Continue?</h1>
          <p><b>{30 - timer}</b> seconds until form resets</p>
          <div className="control-buttons">
            <div>
              <FrameButton clickFn={onConfirm} text="YES"/>
              <LinkButton clickFn={onClose} text="NO"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IdleTimeoutModal;

