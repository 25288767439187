import React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import BackArrow from "../../images/back-arrow.png"
import BrandImage from '../../images/brand-logo.svg';

const NavbarReturn = ({returnText, to}) => {
  return (
    <div className="navbar-return">
      <Link to={to}>
        <img src={BackArrow} width="19" height="auto"/>
        <span>{returnText}</span>
      </Link>
      <div className="brand">
        <div>
          <img src={BrandImage} width="118" height="auto"/>
        </div>
      </div>
    </div>
  );
}

NavbarReturn.propTypes = {
  returnText: PropTypes.string,
  to: PropTypes.string.isRequired
};
NavbarReturn.defaultProps = {
  returnText: "RETURN"
}

export default NavbarReturn;
