import React from "react";
import PropTypes from "prop-types";
import TrashIcon from "../../images/trash-icon.png";

const TrashButton = ({clickFn}) => {
  return (
    <button onClick={clickFn} className="trash-button">
      <img src={TrashIcon} />
    </button>
  );
}

export default TrashButton;

TrashButton.propTypes = {
  clickFn: PropTypes.func
};

TrashButton.defaultProps = {
  clickFn: () => {}
}
