import React from "react";
import {Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {loginAdmin} from "../../core/actions/authActions";
import { useForm } from "react-hook-form";

const LoginAdmin = () => {

  const navigate = useNavigate();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const { register, handleSubmit} = useForm();

  const onSubmit = (data) => {
    dispatch(loginAdmin(data, navigate));
  }

  const onPlayerQueueClick = () => {
    navigate('/player-queue');
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />
  }

  return (
    <div className="auth">
      <h1>Administrator Login</h1>
      <form className="login" onSubmit={handleSubmit(onSubmit)}>
        <div className="fieldset">
          <label htmlFor="email">email:</label>
          <input {...register("email", { required: true })} />
        </div>
        <div className="fieldset">
          <label htmlFor="password">password:</label>
          <input type="password" {...register("password", { required: true })} />
        </div>
        <div className="fieldset">
          <input type="submit" />
        </div>
      </form>
      <div style={{"textAlign":"center", "marginTop":"420px"}}>
        <button onClick={onPlayerQueueClick}>Player Queue</button>
      </div>
    </div>
  );
}

export default LoginAdmin;