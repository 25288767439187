import dataService from "../services/data.service"

import {GET_ERRORS, SET_CURRENT_PLAYERS, SET_INLINE_PLAYERS, SET_REGISTERED_PLAYERS, SET_LEADERBOARD} from "./types";

export const activatePlayer = (id) => dispatch => {
  const success = () => {
    window.location.reload(true);
  }
  dataService('/players/activate-player', 'put', {id})
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const deactivatePlayer = (id) => dispatch => {
  const success = () => {
    window.location.reload(true);
  }
  dataService('/players/deactivate-player', 'put', {id})
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const deletePlayer = (id) => dispatch => {
  const success = () => {
    window.location.reload(true);
  }
  dataService('/players/delete-player', 'delete', {data: {id}})
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const getPlayers = (route, navigate) => dispatch => {
  const success = (res) => {
    dispatch(setCurrentPlayers(res.data));
    if (navigate) {
      navigate(route);
    }
  }
  dataService('/players/get-players', 'get')
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


export const getPlayersInLine = (route, navigate) => dispatch => {
  const success = (res) => {
    dispatch(setInlinePlayers(res.data));
    if (navigate) {
      navigate(route);
    }
  }
  dataService('/players/get-inline-players', 'get')
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const getRegisteredPlayers = (route, navigate) => dispatch => {
  const success = (res) => {
    dispatch(setRegisteredPlayers(res.data));
    if (navigate) {
      navigate(route);
    }
  }
  dataService('/players/get-registered-players', 'get')
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


export const getLeaderboard = () => dispatch => {
  const success = (res) => {
    dispatch(setLeaderboard(res.data));
  }
  dataService('/players/get-leaderboard?startTime=1676559600000', 'get')
  .then(success)
  .catch(err => 
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    }));
};

export const movePlayerOnDeck = (id) => dispatch => {
  const success = () => {
    window.location.reload(true);
  }
  dataService('/players/ondeck-player', 'put', {id})
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const registerPlayer = (playerData, navigate) => dispatch => {
  const success = () => {
    dispatch(getPlayers('/player-registration-complete', navigate));
  }
  dataService('/players/register-player', 'post', playerData)
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


export const inlinePlayer = (id) => dispatch => {
  const success = () => {
    window.location.reload(true);
  }
  dataService('/players/inline-player', 'put', {id})
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


export const removeInlinePlayer = (id) => dispatch => {
  const success = () => {
    window.location.reload(true);
  }
  dataService('/players/leaveline-player', 'put', {id})
  .then(success)
  .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const registerPlayerKiosk = (playerData, navigate) => dispatch => {
  const success = () => {
    dispatch(getPlayers('/player-registration-complete', navigate));
  }
  dataService('/players/register-player', 'post', playerData)
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const reorderPlayers = (reorderObj) => dispatch => {
  const success = () => {
    window.location.reload(true);
  }
  dataService('/players/reorder-players', 'put', reorderObj)
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const recordDistance = (distanceObj) => dispatch => {
  const success = () => {
    window.location.reload(true);
  }
  dataService('/players/save-player-data', 'put', {trackman_json_data: JSON.stringify(distanceObj)})
    .then(success)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const setLeaderboard = (data) => {
  return {
    type: SET_LEADERBOARD,
    payload: data
  };
};

export const setCurrentPlayers = (data) => {
  return {
    type: SET_CURRENT_PLAYERS,
    payload: data
  };
};


export const setRegisteredPlayers = (data) => {
  return {
    type: SET_REGISTERED_PLAYERS,
    payload: data
  };
};

export const setInlinePlayers = (data) => {
  return {
    type: SET_INLINE_PLAYERS,
    payload: data
  };
};

export const clearErrors = () => {
  return {
    type: GET_ERRORS,
    payload: {}
  };
};
