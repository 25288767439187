import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {logoutAdmin} from "../../core/actions/authActions";

const Navbar = () => {

  const [hideBack, setHideBack] = useState(true);
  const [hideNav, setHideNav] = useState(false);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();
  const adminName = useSelector(state => state.auth.admin.name);

  const hideAdminNavigation = (route) => {
    const routes = [
      '/official-rules',
      '/player-management',
      '/player-management-admin',
      '/player-management-queue',
      '/player-queue',
      '/player-queue-sizzle',
      '/register-players-kiosk',
      '/player-registration-complete',
      '/player-registration-complete-kiosk',
      '/player-registration-form',
      '/player-registration-form-kiosk',
      '/privacy-policy',
      '/register-players',
      '/leaderboard',
      '/leaderboard-sizzle',
      '/shareable',
      '/sizzle1',
      '/sizzle2',
    ];
    return routes.some(elem => elem === route);
  }

  useEffect(() => {
    if (hideAdminNavigation(location.pathname)) {
      setHideNav(true);
    } else {
      setHideNav(false);
    }
    if (location.pathname === '/dashboard') {
      setHideBack(true);
    } else {
      setHideBack(false);
    }
  }, [location]);

  const AuthTrue = () => {

    const dispatch = useDispatch();

    const onGotoDashboard = () => {
      navigate('/dashboard');
    }

    const onLogout = () => {
      dispatch(logoutAdmin(navigate));
    }

    return (
      <div className="navbar-auth-true">
        <span className={`back${hideBack ? ' hide' : ''}`}><button onClick={onGotoDashboard}>{`<--`}</button></span>
        <span>currently logged in as: {adminName}</span>
        <span><button onClick={onLogout}>logout</button></span>
      </div>
    )
  }

  const AuthFalse = () => {

    const onLogin = () => {
      navigate('/login-admin');
    }

    const onRegister = () => {
      navigate('/register-admin');
    }

    return (
      <div className="navbar-auth-false">
        {
          (location.pathname === "/" || location.pathname === "/login-admin")
          &&
          (<span><button onClick={onRegister}>register</button></span>)
        }
        {location.pathname === "/register-admin" && (<span><button onClick={onLogin}>login</button></span>)}
      </div>
    )
  }

  return (
    <div className={`navbar${hideNav ? ' hide' : ''}`}>
      {isAuthenticated ? (<AuthTrue/>) : (<AuthFalse/>)}
    </div>
  );
}

export default Navbar;
