import React from "react";
import PropTypes from "prop-types";
import UpArrow from "../../images/up-arrow-icon.png";

const UpArrowButton = ({clickFn}) => {
  return (
    <button onClick={clickFn} className="up-arrow-button">
      <img src={UpArrow} />
    </button>
  );
}

export default UpArrowButton;

UpArrowButton.propTypes = {
  clickFn: PropTypes.func
};

UpArrowButton.defaultProps = {
  clickFn: () => {}
}
