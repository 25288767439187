import React from 'react';
import {Route, Routes} from 'react-router-dom';
import LoginAdmin from '../auth/login-admin';
import RegisterAdmin from '../auth/register-admin';
import PlayerQueue from '../player-queue/player-queue';
import OfficialRules from '../legal/official-rules';
import PrivacyPolicy from '../legal/privacy-policy';
import Images from '../legal/privacy-policy';
import Shareable from '../shareable/shareable';
import Leaderboard from '../leaderboard/leaderboard';
import Sizzle from '../sizzle/sizzle';
import Livestream from '../livestream/livestream';

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<LoginAdmin/>}/>
      <Route exact path="/login-admin" element={<LoginAdmin/>}/>
      <Route exact path="/register-admin" element={<RegisterAdmin/>}/>
      <Route path="/player-queue" element={<PlayerQueue loop={true}/>}/>
      <Route path="/player-queue-sizzle" element={<PlayerQueue loop={false}/>}/>
      <Route path="/official-rules" element={<OfficialRules/>}/>
      <Route path="/images" element={<Images/>}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
      <Route path="/shareable" element={<Shareable/>}/>
      <Route path="/leaderboard" element={<Leaderboard loop={true} livestream={false} />}/>
      <Route path="/leaderboard-sizzle" element={<Leaderboard loop={false} livestream={false} />}/>
      <Route path="/leaderboard-livestream/:id" element={<Leaderboard loop={false} livestream={true} />}/>
      <Route path="/sizzle1" element={<Sizzle targetRoute="/player-queue-sizzle"/>}/>
      <Route path="/sizzle2" element={<Sizzle targetRoute="/leaderboard-sizzle"/>}/>
      <Route path="/sizzle-livestream/:id" element={<Sizzle targetRoute="/livestream"/>}/>
      <Route path="/livestream/:id" element={<Livestream/>}/>
    </Routes>
  );
}
