import axios from "axios";

import config from "../../config";

const host = window.location.host
let axiosInstance = null;

if (host === "localhost:3000") {
  axiosInstance = axios.create({baseURL: config.localApiBaseURL});
} else {
  axiosInstance = axios.create({baseURL: config.prodApiBaseURL});
}

const dataService = (route, method = 'get', payload) => {
  return axiosInstance[method](`${route}`, payload);
}

export default dataService;