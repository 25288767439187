import React from "react";
import PropTypes from 'prop-types';
import {useNavigate,useParams} from "react-router-dom";
import Video from "../../video/GenesisAttractLoop2023.mp4";

const Sizzle = ({targetRoute}) => {

  const navigate = useNavigate();
  let { id } = useParams();

  const vidComplete = () => {
    if (targetRoute) {
      navigate(targetRoute+'/'+id);
    }
  }

  return (
    <div className="sizzle">
      <video src={Video} autoPlay={true} muted onEnded={vidComplete}/>
    </div>
  );
}

export default Sizzle;

Sizzle.propTypes = {
  targetRoute: PropTypes.string
};

Sizzle.defaultProps = {
  targetRoute: ''
};