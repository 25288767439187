import React from "react";
import PropTypes from "prop-types";
import CheckLine from "../../images/check-mark-circle-icon.webp";

const RemoveFromLineButton = ({clickFn}) => {
  return (
    <button onClick={clickFn} className="remove-from-line-button">
      <img src={CheckLine} style={{height: '36px'}} />
    </button>
  );
}

export default RemoveFromLineButton;

RemoveFromLineButton.propTypes = {
  clickFn: PropTypes.func
};

RemoveFromLineButton.defaultProps = {
  clickFn: () => {}
}
