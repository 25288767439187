import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import BrandLogo from "../../images/brand-logo.svg";
import {FrameButton} from "../ui-elements";
import {getPlayers} from "../../core/actions/playerActions";

const PlayerRegistrationCompleteKiosk = () => {
  const [timer, setTimer] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const currentPlayers = useSelector(state => state.players.currentPlayers);

  const onNewSignUp = () => {
    navigate("/player-registration-form-kiosk");
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate('/player-registration-form-kiosk');
  //   }, 30000);
  //   return () => clearTimeout(timer);
  // }, []);

  
  useEffect(() => {
    dispatch(getPlayers());
  }, []);

  useEffect(() => {
    if (timer < 30) {
      setTimeout(() => {
        setTimer(timer + 1);
      }, 1000);
    } else {
      navigate('/player-registration-form-kiosk');
      // dispatch(getPlayers()); TODO : swap these to make it not the line system
    }
}, [timer])

  return (
    <div className="player-registration-complete">
      <div className="header">
        <div className="brand">
          <img src={BrandLogo}/>
        </div>
        <div className="title">CLOSEST TO THE PIN CHALLENGE</div>
      </div>
      <div className="success">SUCCESS!</div>
      <div className="line-place">WHEN YOU'RE READY TO PLAY , CHECK IN AT THE EXPERIENCE BOOTH TO JOIN THE QUEUE</div>
    
      <div className="new-signup">
        <FrameButton text="NEW SIGN UP" clickFn={onNewSignUp}/><br/>
      <span>RESTARTING IN {30 - timer} SECONDS</span>
      </div>
      <div className="close">
        <Link to="/player-registration-form-kiosk">Home</Link>
      </div>
    </div>
  );
}

export default PlayerRegistrationCompleteKiosk;
