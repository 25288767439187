import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {getPlayers,getPlayersInLine} from "../../core/actions/playerActions";
import {LinkButton} from "../ui-elements/"

const PageReload = () => {

  const [timer, setTimer] = useState(0);
  const dispatch = useDispatch();

  const onRefresh = () => {
    window.location.reload(true);
  }

  useEffect(() => {
    if (timer < 30) {
      setTimeout(() => {
        setTimer(timer + 1);
      }, 1000);
    } else {
      setTimer(0);
      return () => {
        clearTimeout();
      };
      // dispatch(getPlayers()); TODO : swap these to make it not the line system
      dispatch(getPlayers());
    }
  }, [timer])
  // TODO : use this on that page with the timer (player registere kiosk complete)

  return(
    <div className="player-management-timer">
      <span><b>{30 - timer}</b> seconds until player data refresh</span>
      <span><LinkButton clickFn={onRefresh} text={'refresh now'}/> </span>
    </div>
  )
}

export default PageReload;
