import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {LinkButton, FrameButton} from "../ui-elements";
import {activatePlayer} from "../../core/actions/playerActions";

const ActivatePlayerModal = ({closeFn, player}) => {

  const dispatch = useDispatch();

  const onCancel = () => {
    closeFn();
  };

  const onConfirm = () => {
    dispatch(activatePlayer(player._id));
    closeFn();
  };

  return (
    <div className="activate-player-modal">
      <div className="modal-frame">
        <h1>Is {player.first_name} ready to start?</h1>
        <div className="control-buttons">
          <div>
            <FrameButton clickFn={onConfirm} text="YES"/>
          </div>
          <div>
            <LinkButton clickFn={onCancel} text="NO"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivatePlayerModal;

ActivatePlayerModal.propTypes = {
  closeFn: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired
};