import React from "react";
import PropTypes from "prop-types";

const FrameButton = ({clickFn, text}) => {
  return (
    <button onClick={clickFn} className="frame-button">{text}</button>
  );
}

export default FrameButton;

FrameButton.propTypes = {
  clickFn: PropTypes.func,
  text: PropTypes.string
};

FrameButton.defaultProps = {
  clickFn: () => {},
  text: ""
}