import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {FrameButton, LinkButton} from "../ui-elements";
// import {clearErrors, registerPlayer} from "../../core/actions/playerActions";
import {clearErrors, registerPlayerKiosk} from "../../core/actions/playerActions";
import PropTypes from "prop-types";

const PlayerExistsModal = ({playerData, navigate}) => {

  const dispatch = useDispatch();
  const errorEmail = useSelector(state => state.errors.email) || undefined;

  const onClose = () => {
    dispatch(clearErrors());
  };

  const onConfirm = () => {
    playerData.email = errorEmail;
    console.log(playerData);
    dispatch(registerPlayerKiosk(playerData, navigate));
    dispatch(clearErrors());
  }

  return (
    <>
      {errorEmail &&
      <div className="player-exists-modal">
        <div className="modal-frame">
          <h1>Thank you for returning to the Closest to the Pin Challenge.</h1>
          <p>Your first entry has been recorded and will count as your official entry into the contest.</p>
          <div className="control-buttons">
            <div>
            {/* <FrameButton clickFn={onConfirm} text="OK"/>
              <LinkButton clickFn={onClose} text="CANCEL"/> */}
              <FrameButton clickFn={onConfirm} text="Proceed"/>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
}

export default PlayerExistsModal;

PlayerExistsModal.propTypes = {
  navigate: PropTypes.func.isRequired,
  playerData: PropTypes.object.isRequired
};
