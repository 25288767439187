import React from "react";
import {Route, Routes} from "react-router-dom";
import {PrivateRoute} from "./private-route";
import Dashboard from "../dashboard/dashboard";
import PlayerRegistration from "../player-registration/player-registration";
import PlayerRegistrationKiosk from "../player-registration/player-registration-kiosk";
import PlayerRegistrationForm from "../player-registration/player-registration-form";
import PlayerRegistrationFormKiosk from "../player-registration/player-registration-form-kiosk";
import PlayerRegistrationComplete from "../player-registration/player-registration-complete";
import PlayerRegistrationCompleteKiosk from "../player-registration/player-registration-complete-kiosk";
import ManagePlayerQueue from "../player-management/manage-player-queue";
import InlinePlayerSearch from "../player-management/player-search";

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
      <Route path="/register-players" element={<PrivateRoute><PlayerRegistration/></PrivateRoute>}/>
      <Route path="/inline-players-search" element={<PrivateRoute><InlinePlayerSearch/></PrivateRoute>}/>
      <Route path="/register-players-kiosk" element={<PrivateRoute><PlayerRegistrationKiosk/></PrivateRoute>}/>
      <Route path="/player-registration-form" element={<PrivateRoute><PlayerRegistrationForm/></PrivateRoute>}/>
      <Route path="/player-registration-form-kiosk" element={<PrivateRoute><PlayerRegistrationFormKiosk/></PrivateRoute>}/>
      <Route path="/player-registration-complete" element={<PrivateRoute><PlayerRegistrationComplete/></PrivateRoute>}/>
      <Route path="/player-registration-complete-kiosk" element={<PrivateRoute><PlayerRegistrationCompleteKiosk/></PrivateRoute>}/>
      <Route path="/player-management-queue" element={<PrivateRoute><ManagePlayerQueue/></PrivateRoute>}/>
    </Routes>
  );
}
