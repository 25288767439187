import React, { useEffect, useState } from 'react'
import dataService from '../../core/services/data.service'
import BrandLogo from '../../images/logo-flip.png'
import DistanceIcon from '../../images/distance-icon-3.svg'
import twIcon from '../../images/tw.svg'
import fbIcon from '../../images/fb.svg'

const Shareable = () => {
  const [isBubbleOn, setIsBubbleOn] = useState(false)
  const [playerId, setPlayerId] = useState('')
  const [playerData, setPlayerData] = useState({})

  useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    const id = params.get('id')
    setPlayerId(id)
  }, [])

  useEffect(() => {
    if (!playerId) {
      return;
    }
    const success = (res) => setPlayerData(res.data)
    dataService(`/players/get-shareable?id=${playerId}`, 'get')
      .then(success)
      .catch((err) => console.error(err))
  }, [playerId])

  const twitterCopy = encodeURIComponent(
    '#GenesisInvitational #MakeTheGameYourOwn'
  )

  const assetUri = `https://closesttothepinchallenge.com/2023-Genesis-Invitational/${playerId}/ClosestToThePinChallenge.mp4`

  const encodedUri = encodeURIComponent(assetUri)

  const fbLink = `https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${encodedUri}&display=popup&ref=plugin&src=share_button`

  return (
    <div className="shareable">
      <div className="header">
        <div className="brand">
          <img src={BrandLogo} alt="genesis brand logo" />
        </div>
      </div>
      <div className="asset-container">
        <h1 className="title-1">make the game your own </h1>
        <section className="media">
          <div className="video">
            {playerData.url && (
              <video
                className="video-player"
                controls
                playsInline
                loop
                autoPlay
                muted
              >
                <source src={assetUri} type="video/mp4" />
              </video>
            )}

            <button
              className="primary"
              onClick={() => setIsBubbleOn(!isBubbleOn)}
            >
              share
              {isBubbleOn && (
                <section className="share-bubble">
                  <a
                    className="fb-button"
                    target="_blank"
                    rel="noreferrer"
                    href={fbLink}
                  >
                    <img src={fbIcon} alt="facebook icon" />
                  </a>
                  <a
                    className="tw-button"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://twitter.com/share?url=${encodedUri}&text=${twitterCopy}`}
                  >
                    <img src={twIcon} alt="twitter icon" />
                  </a>
                  <div className="pointer"></div>
                </section>
              )}
            </button>
            <a
              className="download"
              target="_blank"
              rel="noreferrer"
              href={assetUri}
              download
            >
              download
            </a>
          </div>
          <div className="distance">
            <img
              className="distance-icon"
              src={DistanceIcon}
              alt="distance icon"
            />
            <p className="stat">
              {playerData?.data?.distanceFeet}'{' '}
              {playerData?.data?.distanceInches}"
            </p>
            <p className="subtitle">distance to pin</p>
          </div>
        </section>
        <footer>
          <p className="tags">#GenesisInvitational #MakeTheGameYourOwn</p>
          <p className="disclaimer">
            Genesis is a registered trademark of Hyundai Motor America. All
            rights reserved. ©2022 Hyundai Motor America.
          </p>
        </footer>
      </div>
    </div>
  )
}

export default Shareable
