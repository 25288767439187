import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerPlayerKiosk } from "../../core/actions/playerActions";
import { useForm } from "react-hook-form";
import { useIdleTimer } from 'react-idle-timer'

import NavbarReturn from "../navigation/navbar-return";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { CheckboxIcon } from "../ui-elements";
import { CheckboxIconChecked } from "../ui-elements";

import PlayerExistsModal from "./player-exists-modal";
import IdleTimeoutModal from "./idle-timeout-modal";
import Over18Modal from "./over-18-modal";


const PlayerRegistrationFormKiosk = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [isOver18, setIsOver18] = useState(true);
  const [over18Error, setOver18Error] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [dealerEmailError, setDealerEmailError] = useState(false);
  const [dealerContact, setDealerContact] = useState(false);
  const [emailConsent, setEmailConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [tcPrivacy, setTcPrivacy] = useState(false);
  const [tcPrivacyError, setTcPrivacyError] = useState(false);
  const [vehicleInterest, setVehicleInterest] = useState("");
  const [dealerTopic, setDealerTopic] = useState("");
  const [tempPlayerData, setTempPlayerData] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);

  const onOver18ToggleChange = (event, val) => {
    if (val === null) {
      return;
    }
    setIsOver18(val);
  };

  const closeOver18ErrorModal = () => {
    setOver18Error(false);
  };

  const onDealerToggleChange = (event, val) => {
    if (val === null) {
      return;
    }
    setDealerContact(val);
  };

  const onEmailConsentToggleChange = (event, val) => {
    setEmailConsent(val);
  };

  const onMarketingConsentCheckboxChange = () => {
    setMarketingConsent(!marketingConsent);
  };

  const onTcPrivacyCheckboxChange = () => {
    setTcPrivacy(!tcPrivacy);
  };

  const onDealerTopicChange = (event) => {
    setDealerTopic(event.target.value);
  };

  const onVehicleInterestChange = (event) => {
    setVehicleInterest(event.target.value);
  };

  const onSubmit = (data) => {
    setDisableSubmit(true);
    const playerData = {
      ...data,
      ...{ over_18: isOver18 },
      ...{ dealer_contact: dealerContact },
      ...{ email_consent: emailConsent },
      ...{ marketing_consent: marketingConsent },
      ...{ tc_privacy: tcPrivacy },
      ...{ dealer_topic: dealerTopic },
      ...{ vehicle_interest: vehicleInterest },
    };
    setTempPlayerData(playerData);
    if (!tcPrivacy) {
      setTcPrivacyError(true);
      setDisableSubmit(false);
    }
    if (!isOver18) {
      setOver18Error(true);
      setDisableSubmit(false);
      return;
    }

    dispatch(registerPlayerKiosk(playerData, navigate));


  };

  useEffect(() => {
    if (tcPrivacy) {
      setTcPrivacyError(false);
    }
    if (dealerContact && emailConsent) {
      setDealerEmailError(false);
    }
  }, [tcPrivacy, dealerContact, emailConsent]);


  const [showIdleTimeout, setIdleTimeout] = useState(false);

  const onIdle = () => {
    console.log('idle')
    setIdleTimeout(true)
    // Close Modal Prompt
    // Do some idle action like log out your user
  }

  const onActive = (event) => {
    // setIdleTimeout(false)

  }

  
  const timeoutMili = 30000;
  const idleTimer = useIdleTimer({ onIdle, onActive, timeout: timeoutMili })

  return (
    <>
      <div className="player-registration-form">
      <NavbarReturn
        returnText="CLOSE"
        to="/register-players-kiosk"
        style={{ marginBottom: "10px" }}
      />
      <h1 style={{ marginBottom: "10px", marginTop: '-36px', fontSize: '28px' }}>REGISTER</h1>

      <form className="registration-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field-group" style={{ marginBottom: "10px" }}>
          <div
            className={`fieldset${errors.first_name ? " error-required" : ""}`}
          >
            <label htmlFor="first_name">FIRST NAME*</label>
            <input {...register("first_name", { required: true })} />
          </div>
          <div
            className={`fieldset${errors.last_name ? " error-required" : ""}`}
          >
            <label htmlFor="last_name">LAST NAME*</label>
            <input {...register("last_name", { required: true })} />
          </div>
        </div>
        <div className="field-group last" style={{ marginBottom: "10px" }}>
          <div className={`fieldset${errors.email ? " error-required" : ""}`}>
            <label htmlFor="email">EMAIL ADDRESS*</label>
            <input type="email" {...register("email", { required: true })} />
          </div>
          <div
            className={`fieldset${errors.phone ? " error-required" : ""} ${
              isOver18 ? "" : "disabled18"
            }`}
          >
            <label htmlFor="phone">
              PHONE NUMBER{dealerContact && <span>*</span>}{" "}
            </label>
            <input
              placeholder="555-555-5555"
              {...register("phone", {
                required: dealerContact || marketingConsent,
              })}
            />
          </div>
          <div
            className={`fieldset${errors.zip_code ? " error-required" : ""}`}
          >
            <label htmlFor="zip_code">ZIP CODE*</label>
            <input
              type="number"
              {...register("zip_code", { required: true })}
            />
          </div>
        </div>
        <div className="field-group toggle" style={{ marginBottom: "10px", display: 'flex' }}>
          <div
            className="fieldset toggle"
            style={{ marginBottom: "10px", width: "50%" }}
          >
            <label htmlFor="over_18">Are You At Least 18 Years of Age?*</label>
            <ToggleButtonGroup
              color="primary"
              value={isOver18}
              exclusive
              onChange={onOver18ToggleChange}
            >
              <ToggleButton value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div
            className={`fieldset toggle ${isOver18 ? "" : "disabled18"}`}
            style={{ marginBottom: "10px", width: "50%" }}
          >
            <label htmlFor="dealer_contact">
              Would You Like a Retailer to Contact You?
            </label>
            <ToggleButtonGroup
              color="primary"
              value={dealerContact}
              exclusive
              onChange={onDealerToggleChange}
            >
              <ToggleButton value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div
          className={`field-group ${isOver18 ? "" : "disabled18"} ${
            dealerContact ? "" : "disabled18"
          }`}
          style={{ marginBottom: "10px" }}
        >
          <div
            className="fieldset select"
            style={{ marginBottom: "10px", width: "50%" }}
          >
            <label htmlFor="dealer_topic">
              What Would You Like to Discuss With The Retailer? {dealerContact?'*':''}
            </label>
            <div
              className={`select ${isOver18 ? "" : "disabled18"} ${
                dealerContact ? "" : "disabled18"
              }`}
            >
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    value={dealerTopic}
                    onChange={onDealerTopicChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    required={dealerContact}
                  >
                    <MenuItem value="">
                      <em>Please Select</em>
                    </MenuItem>
                    <MenuItem value="PRICE QUOTE">PRICE QUOTE</MenuItem>
                    <MenuItem value="TEST DRIVE">TEST DRIVE</MenuItem>
                    <MenuItem value="PURCHASE OR LEASE">
                      PURCHASE OR LEASE
                    </MenuItem>
                    <MenuItem value="PRODUCTS AND SERVICES">
                      PRODUCTS AND SERVICES
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </div>
        <div
          className={`field-group ${isOver18 ? "" : "disabled18"} ${
            dealerContact ? "" : "disabled18"
          }`}
          style={{marginBottom: '10px', width: '50%'}}
        >
          <div className="fieldset select">
            <label htmlFor="vehicle_intrest">
              What Model Are You Interested In? {dealerContact?'*':''}
            </label>
            <div
              className={`select ${isOver18 ? "" : "disabled18"} ${
                dealerContact ? "" : "disabled18"
              }`}
            >
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    value={vehicleInterest}
                    onChange={onVehicleInterestChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    required={dealerContact}
                  >
                    <MenuItem value="">
                      <em>Please Select</em>
                    </MenuItem>
                    <MenuItem value="GENESIS G70">GENESIS G70</MenuItem>
                    <MenuItem value="GENESIS G80">GENESIS G80</MenuItem>
                    <MenuItem value="GENESIS Electrified G80">
                      GENESIS Electrified G80
                    </MenuItem>
                    <MenuItem value="GENESIS G90">GENESIS G90</MenuItem>
                    <MenuItem value="GENESIS GV60">GENESIS GV60</MenuItem>
                    <MenuItem value="GENESIS GV70">GENESIS GV70</MenuItem>

                    <MenuItem value="GENESIS GV80">GENESIS GV80</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </div>

        <div
          className="duo-checkbox-field-group"
          style={{ marginBottom: "10px", height: '60px' }}
        >
          <div className="field-group checkbox one">
            <div className="fieldset checkbox">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon error={!tcPrivacy} />}
                      checkedIcon={<CheckboxIconChecked />}
                      onChange={onTcPrivacyCheckboxChange}
                      required={true}
                    />
                  }
                  label={
                    <div
                      className={`text${
                        tcPrivacyError ? " error-checkbox" : ""
                      }`}
                    >
                      <span>
                        By checking this box you acknowledge that you have read
                        and agreed to the{" "}
                      </span>
                      <span className="emphasize">
                        <Link to={"/official-rules"}>Official Rules</Link>
                      </span>
                      <span> and our </span>
                      <span className="emphasize">
                      <Link to={"/privacy-policy"}>Privacy Policy*</Link>

                        {/* <a href="https://www.genesis.com/us/en/privacy-portal.html">
                          Privacy Policy*
                        </a> */}
                      </span>
                      <span>.</span>
                    </div>
                  }
                />
              </FormGroup>
            </div>
          </div>

          <div
            className={`field-group checkbox two ${
              isOver18 ? "" : "disabled18"
            }`}
          >
            <div className="fieldset checkbox">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxIconChecked />}
                      onChange={onEmailConsentToggleChange}
                    />
                  }
                  label={
                    // <div className="text">dealerEmailError
                    // className={`text${dealerEmailError ? ' error-checkbox' : ''}`}
                    <div>
                      <span>
                        I would like to receive news, product information, and
                        other exclusive offers from Genesis.
                      </span>
                    </div>
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>

        <div style={{height: '75px'}}>
          <div style={{height: 'auto'}}
            className={`field-group checkbox two  ${
              isOver18 ? "" : "disabled18"
            }`}
          >
            <div className="fieldset checkbox">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                    icon={<CheckboxIcon error={dealerContact} />}
                    checkedIcon={<CheckboxIconChecked />}
                      onChange={onMarketingConsentCheckboxChange}
                      required={dealerContact}
                    />
                  }
                  label={
                    <div className="text">
                      <span>
                        I consent to receiving telemarketing calls or texts at
                        this number using an automatic telephone dialing system
                        by, or on behalf of, Genesis and its authorized
                        retailers. I understand I do not have to consent in
                        order to purchase any products or services. Carrier
                        charges may apply.  {dealerContact?'*':''}
                      </span>
                    </div>
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="submit" style={{ marginBottom: "10px" , zIndex: 100}}>
          <input
            type="submit"
            value="SUBMIT"
            className={disableSubmit ? "disable-submit" : ""}
          />
          {Object.keys(errors).length > 0 || tcPrivacyError ? (
            <div className="error-message">
              *PLEASE CHECK THE REQUIRED FIELDS
            </div>
          ) : (
            <div className="required-key-message">*REQUIRED</div>
          )}

          <div className="cancel" style={{marginTop: '5px'}}>
          <span
        type="button"
        onClick={() => window.location.reload()}
        className="btn btn-secondary"
      >
        Start Over
      </span>          </div>
        </div>
      </form>
      <div></div>
      <PlayerExistsModal navigate={navigate} playerData={tempPlayerData} />
      {showIdleTimeout && (
            <IdleTimeoutModal setIdleTimeout={setIdleTimeout} timeoutMili={timeoutMili}/>
      )}
      {over18Error && (
        <Over18Modal
          closeFn={closeOver18ErrorModal}
          navigate={navigate}
          playerData={{ ...tempPlayerData }}
        />
      )}
    </div>
    </>
  );
};

export default PlayerRegistrationFormKiosk;
