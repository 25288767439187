import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {LinkButton} from "../ui-elements";
import {recordDistance} from "../../core/actions/playerActions";

const RecordDistanceModal = ({closeFn, player}) => {

  const dispatch = useDispatch();

  const {register, formState: {errors}, handleSubmit} = useForm();

  const onCancel = () => {
    closeFn();
  };

  const onSubmit = (data) => {
    dispatch(recordDistance(data));
    closeFn();
  }

  return (
    <div className="record-distance-modal">
      <div className="modal-frame">
        <h1>Enter the players' final distance to the pin.</h1>
        <form className="record-data-form" onSubmit={handleSubmit(onSubmit)}>
          <input {...register("player_id")} hidden={true} defaultValue={player._id}/>
          <div className="entry-fields">
            <input {...register("distanceFeet", {required: true})} type="number" />
            <span>'</span>
            <input {...register("distanceInches", {required: true})} type="number" />
            <span>"</span>
          </div>
          <div className="closest-distance">CLOSEST DISTANCE</div>
          <div className="submit-btn"><input type="submit"/></div>
        </form>
        <div>
          <LinkButton clickFn={onCancel} text="CANCEL"/>
        </div>
      </div>
    </div>
  );
}

export default RecordDistanceModal;

RecordDistanceModal.propTypes = {
  closeFn: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired
};