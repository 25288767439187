import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {LinkButton, FrameButton} from "../ui-elements";
import {deactivatePlayer} from "../../core/actions/playerActions";

const DeactivatePlayerModal = ({closeFn, player}) => {

  const dispatch = useDispatch();

  const hasData = (Object.keys(player.trackman_json_data).length > 0);

  const onCancel = () => {
    closeFn();
  };

  const onConfirm = () => {
    dispatch(deactivatePlayer({id: player._id}));
    closeFn();
  };

  return (
    <div className="deactivate-player-modal">
      <div className="modal-frame">
        <h1>Are you sure you want to deactivate {player.first_name}?</h1>
        <p>Before deactivation confirm you've saved {player.first_name}'s distance to the pin and also confirm all videos have
          been uploaded and processed.</p>
        <div className="control-buttons">
          <div style={
            {
              "pointerEvents": (hasData) ? 'all' : 'none',
              "opacity": (hasData) ? '1' : '0.25'
            }
          }>
            <FrameButton clickFn={onConfirm} text="YES"/>
          </div>
          <div>
            <LinkButton clickFn={onCancel} text="NO"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeactivatePlayerModal;

DeactivatePlayerModal.propTypes = {
  closeFn: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired
};