import React from "react";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch} from 'react-redux';
import {logoutAdmin} from "../../core/actions/authActions";
import {FrameButton, LinkButton} from "../ui-elements"
import BrandImage from '../../images/brand-logo.svg';

const PlayerRegistration = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const onAdminSelect = () => {
  //   dispatch(logoutAdmin(navigate));
  // }

  const onSignUp = () => {
    navigate('/player-registration-form-kiosk');
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate('/player-registration-form-kiosk');
  //   }, 5000);
  //   return () => clearTimeout(timer);
  // }, []);
 
  return (
    <div className="player-registration">
      <div className="brand-title">
        <span className="brand">
          <div>
            <img width="143" height="auto" src={BrandImage}/>
          </div>
        </span>
        <span className="pipe"></span>
        <span className="title">CLOSEST TO THE PIN CHALLENGE</span>
      </div>
      <div className="sign-up">
        <FrameButton clickFn={onSignUp} text="REGISTER"/>
      </div>
      {/* <div className="admin">
        <LinkButton clickFn={onAdminSelect} text="ADMIN"/>
      </div> */}
    </div>
  );
}

export default PlayerRegistration;
