import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {FrameButton, LinkButton} from "../ui-elements";
import {registerPlayer, registerPlayerKiosk} from "../../core/actions/playerActions";

const Over18Modal = ({closeFn, navigate, playerData}) => {

  const dispatch = useDispatch();

  const onConfirm = () => {
    console.log(playerData);
    dispatch(registerPlayerKiosk(playerData, navigate));
    closeFn()
  }

  return (
    <div className="player-under18-modal">
      <div className="modal-frame">
        <h1>AGE NOTICE</h1>
        <p>You must be at least 18 years of age to qualify for the Grand Prize and Daily Prizes.</p>
        <div className="control-buttons">
          <div>
            <FrameButton clickFn={onConfirm} text="OK"/>
            <LinkButton clickFn={closeFn} text="CANCEL"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Over18Modal;

Over18Modal.propTypes = {
  closeFn: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  playerData: PropTypes.object.isRequired
};
