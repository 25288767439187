import React from "react";
import PropTypes from "prop-types";
import StarActive from "../../images/star-active.png";
import StarInactive from "../../images/star-inactive.png";

const FlagButton = ({clickFn, isActive}) => {
  return (
    <button onClick={clickFn} className="star-button">
      {isActive ? <img src={StarActive} /> : <img src={StarInactive} />}
    </button>
  );
}

export default FlagButton;

FlagButton.propTypes = {
  clickFn: PropTypes.func,
  isActive: PropTypes.bool
};

FlagButton.defaultProps = {
  clickFn: () => {},
  isActive: false
}