import {
  SET_CURRENT_PLAYERS,
  SET_INLINE_PLAYERS,
  SET_REGISTERED_PLAYERS,
  SET_LEADERBOARD,
} from "../actions/types";

const initialState = {
  inlinePlayers: [],
  registeredPlayers: [],
  currentPlayers: [],
  leaderboard: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PLAYERS:
      return {
        ...state,
        currentPlayers: action.payload,
      };
    case SET_INLINE_PLAYERS:
      return {
        ...state,
        inlinePlayers: action.payload,
      };
    case SET_REGISTERED_PLAYERS:
      return {
        ...state,
        registeredPlayers: action.payload,
      };
    default:
      return state;
    case SET_LEADERBOARD:
      return {
        ...state,
        leaderboard: action.payload,
      };
  }
}
