import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {LinkButton, FrameButton} from "../ui-elements";
import {deletePlayer} from "../../core/actions/playerActions";

const DeletePlayerModal = ({closeFn, player}) => {

  const dispatch = useDispatch();

  const onCancel = () => {
    closeFn();
  };

  const onConfirm = () => {
    dispatch(deletePlayer(player._id));
    closeFn();
  };

  return (
    <div className="delete-player-modal" style={{textAlign: 'center'}}>
      <div className="modal-frame">
        <h1>Are you sure you want to delete {player.first_name}?</h1>
        <div className="control-buttons">
          <div>
            <FrameButton clickFn={onConfirm} text="YES"/>
          </div>
          <div>
            <LinkButton clickFn={onCancel} text="NO"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeletePlayerModal;

DeletePlayerModal.propTypes = {
  closeFn: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired
};