import React from "react";
import DraggableIcon from "../../images/draggable-icon.png";

const DraggableTarget = () => {
  return (
    <button data-movable-handle className="draggable-target">
      <img src={DraggableIcon} />
    </button>
  );
}

export default DraggableTarget;
