import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import dataService from "../services/data.service"

import { GET_ERRORS, SET_CURRENT_ADMIN } from "./types";

export const registerAdmin = (userData, navigate) => dispatch => {
  dataService('/admins/register', 'post', userData)
  .then(res => navigate('/login-admin'))
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  );
};

export const loginAdmin = (adminData, navigate) => dispatch => {
  dataService('/admins/login', 'post', adminData)
  .then(res => {
    const { token } = res.data;
    localStorage.setItem("jwtToken", token);
    setAuthToken(token);
    const decoded = jwt_decode(token);
    dispatch(setCurrentAdmin(decoded));
    navigate('/dashboard');
  })
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  );
};

export const setCurrentAdmin = decoded => {
  return {
    type: SET_CURRENT_ADMIN,
    payload: decoded
  };
};

export const logoutAdmin = (navigate) => dispatch => {
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentAdmin({}));
  navigate('/');
};
