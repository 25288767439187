import React from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {registerAdmin} from "../../core/actions/authActions";
import {useForm} from "react-hook-form";


const RegisterAdmin = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {register, handleSubmit} = useForm();
  const onSubmit = (data) => {
    dispatch(registerAdmin(data, navigate));
  }

  return (
    <div className="auth">
      <h1>Administrator Registration</h1>
      <form className="register" onSubmit={handleSubmit(onSubmit)}>
        <div className="fieldset">
          <label htmlFor="name">name:</label>
          <input {...register("name", {required: true})} />
        </div>
        <div className="fieldset">
          <label htmlFor="email">email:</label>
          <input {...register("email", {required: true})} />
        </div>
        <div className="fieldset">
          <label htmlFor="password">password:</label>
          <input type="password" {...register("password", {required: true})} />
        </div>
        <div className="fieldset">
          <label htmlFor="password2">confirm password:</label>
          <input type="password" {...register("password2", {required: true})} />
        </div>
        <input type="submit"/>
      </form>
    </div>
  );
}

export default RegisterAdmin;
